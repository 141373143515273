import React, { useEffect, Fragment } from 'react';
import { observer } from 'mobx-react-lite';
import { Container, LoadingBar, Notice } from '@administrate/piston';
import PropTypes from 'prop-types';
import { StickyContainer, Sticky } from 'react-sticky';
import { withTranslation } from 'react-i18next';

import { LoadingScreen, OpacityOverlay } from '@administrate/piston-ux';
import inject from '../inject';
import withIntegration from '../withIntegration';
import { STORES } from '../../constants';
import CheckoutProgress from '../../components/Checkout/CheckoutProgress/CheckoutProgress';
import CheckoutSummaryCard from './CheckoutSummaryCard';
import Details from './Details';
import Learners from './Learners';
import Payment from './Payment';
import ReviewOrder from './ReviewOrder';
import getDomainId from '../../utils/getDomainId';
import PromotionCodeInput from '../Cart/PromotionCodeInput';
import GiftVoucherInput from '../Cart/GiftVoucherInput';
import portalStore from '../../stores/portalStore';
import { ProgressList } from '../../stores/checkoutStore';
import { CartExpiryCountdown } from '../../components/Cart/CartExpiryCountdown';
import { SummaryCardSkeleton } from '../../components/SummaryCard/SummaryCardSkeleton';
import { DetailsSkeleton } from './DetailsSkeleton';
import { LearnersSkeleton } from './LearnersSkeleton';
import { PaymentMethodSkeleton } from './Payment/PaymentMethodSkeleton';
import WeblinkNavigation from '../WeblinkNavigation/WeblinkNavigation';
import useQueryParams from '../../hooks/useQueryParams';
import ReturnToCatalogue from '../ReturnToCatalogue';
import { CartEvent } from '../../analytics';

const {
  STORE_ANALYTICS,
  STORE_CHECKOUT,
  STORE_CART,
  STORE_REVIEW_ORDER,
  STORE_STORE,
  STORE_NAVIGATION,
} = STORES;

const ErrorNoticeContent = withTranslation()(
  observer(
    ({
      id,
      t,
      learnerAlreadyRegisteredError,
      noRemainingSeatsError,
      backToCart,
      defaultErrorMessage,
      integration,
      checkoutError,
    }) => (
      <>
        {!noRemainingSeatsError &&
          !learnerAlreadyRegisteredError &&
          !checkoutError && (
            <>
              <div>{defaultErrorMessage}</div>
              <div>{t('weblink:referToOrder', { id })}</div>
            </>
          )}
        {learnerAlreadyRegisteredError && (
          <div>
            {t('weblink:learnerAlreadyRegisteredError', {
              eventTitle: learnerAlreadyRegisteredError.title,
            })}
          </div>
        )}
        {noRemainingSeatsError && (
          <>
            <div>
              {t('weblink:noRemainingSeatsError', {
                eventTitle: noRemainingSeatsError.title,
              })}
            </div>
            <div>
              {!integration && (
                <span
                  onClick={() => backToCart()}
                  onKeyPress={() => backToCart()}
                  role="link"
                  tabIndex={0}
                  className="btn btn-link"
                >
                  {t('weblink:backToCart')}
                </span>
              )}
            </div>
          </>
        )}
        {checkoutError && t(checkoutError)}
      </>
    ),
  ),
);

ErrorNoticeContent.propTypes = {
  id: PropTypes.string.isRequired,
};

const SuccessNoticeContent = withTranslation()(({ t }) => (
  <div>
    <div>{t('weblink:orderSuccess')}</div>
  </div>
));

const CheckoutSteps = ({
  embedSite,
  cartState,
  t,
  isLoading,
  progress,
  integration,
  isApplyPromotionCodeLoading,
  isCheckoutLoading,
  applyPromotionCode,
  applyGiftVoucher,
  giftVouchersEnabled,
  reloadCart,
  reservationsValidUntil,
  handleCartExpiry,
  backToCart,
}) => {
  const isOrderRetrievalCart = cartState === 'orderRetrieval';

  const onCartExpiryAcknowledged = () => {
    if (embedSite) {
      window.location = embedSite;
    } else {
      backToCart();
    }
  };

  return (
    <Container>
      {reservationsValidUntil && (
        <Container.Row>
          <Container.Col lg={6} lgOffset={1} md={7}>
            <div className="checkout-reservations-timer-container">
              <CartExpiryCountdown
                t={t}
                integration={!!embedSite}
                reservationsValidUntil={reservationsValidUntil}
                onComplete={reloadCart}
                handleCartExpiry={handleCartExpiry}
                redirect={onCartExpiryAcknowledged}
              />
            </div>
          </Container.Col>
        </Container.Row>
      )}
      <Container.Row>
        <Container.Col lgOffset={1} lg={3} sm={5} smPush={7} lgPush={7}>
          {!isLoading ? (
            <CheckoutSummaryCard
              isOrderRetrievalCart={isOrderRetrievalCart}
              canRemovePromotionCode
            />
          ) : (
            <SummaryCardSkeleton t={t} showAmendCart={!isOrderRetrievalCart} />
          )}
          {progress.currentStep.item.name !== 'completed' &&
            !isOrderRetrievalCart && (
              <OpacityOverlay on={isLoading}>
                <PromotionCodeInput
                  isLoading={isApplyPromotionCodeLoading || isCheckoutLoading}
                  applyPromotionCode={applyPromotionCode}
                />
                {giftVouchersEnabled && (
                  <GiftVoucherInput
                    isLoading={isApplyPromotionCodeLoading || isCheckoutLoading}
                    applyGiftVoucher={applyGiftVoucher}
                  />
                )}
              </OpacityOverlay>
            )}
        </Container.Col>
        <Container.Col lg={7} lgPull={3} sm={7} smPull={5}>
          <div className="checkout_booking-information">
            {isLoading
              ? {
                  buyer_info: <DetailsSkeleton t={t} />,
                  learner_info: <LearnersSkeleton t={t} />,
                  payment: <PaymentMethodSkeleton t={t} />,
                }[progress.currentStep.item.name]
              : {
                  buyer_info: <Details integration={integration} />,
                  learner_info: <Learners integration={integration} />,
                  payment: <Payment integration={integration} />,
                }[progress.currentStep.item.name]}
          </div>
        </Container.Col>
      </Container.Row>
    </Container>
  );
};

CheckoutSteps.propTypes = {
  embedSite: PropTypes.string,
  cartState: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  applyPromotionCode: PropTypes.func.isRequired,
  applyGiftVoucher: PropTypes.func.isRequired,
  giftVouchersEnabled: PropTypes.bool.isRequired,
  integration: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  isApplyPromotionCodeLoading: PropTypes.bool.isRequired,
  isCheckoutLoading: PropTypes.bool.isRequired,
  progress: PropTypes.instanceOf(ProgressList).isRequired,
  reloadCart: PropTypes.func,
  reservationsValidUntil: PropTypes.string,
  handleCartExpiry: PropTypes.func.isRequired,
  backToCart: PropTypes.func.isRequired,
};

CheckoutSteps.defaultProps = {
  embedSite: null,
  reservationsValidUntil: null,
  reloadCart: undefined,
};

const Checkout = ({
  [STORE_ANALYTICS]: { captureEvent },
  [STORE_CHECKOUT]: {
    isLoading: isCheckoutLoading,
    showNotice,
    checkoutProgressPath,
    progress,
  },
  [STORE_CART]: {
    isApplyPromotionCodeLoading,
    applyPromotionCode,
    applyGiftVoucher,
    changeUsedCart,
    cartSize,
    cart,
    learnerAlreadyRegisteredError,
    noRemainingSeatsError,
    backToCart,
    isLoading: isCartLoading,
    isError,
    changeIsError,
    checkoutError,
    reloadCart,
    reservationsValidUntil,
    handleCartExpiry,
  },
  [STORE_NAVIGATION]: { toCatalogue },
  [STORE_STORE]: { defaultErrorMessage, giftVouchersEnabled },
  t,
  integration,
}) => {
  const isLoading = isCheckoutLoading || isCartLoading;
  const { id, state: cartState } = cart;
  const params = useQueryParams();
  const redirectUrl = params.get('postCheckoutRedirectUrl');

  useEffect(
    () => () => {
      // In case the user leaves checkout before finishing the purchase
      changeUsedCart('shoppingCart');
    },
    [changeUsedCart],
  );

  useEffect(() => {
    if (
      !isLoading &&
      !isError &&
      progress.currentStep?.item?.name === 'buyer_info'
    ) {
      captureEvent(CartEvent.fromCheckoutBegin({ cart }));
    }
  }, [cart, isLoading, isError, captureEvent]);

  useEffect(() => {
    if (redirectUrl) {
      portalStore.setPostCheckoutRedirectUrl(redirectUrl);
    }
  }, [redirectUrl]);

  const isCheckoutStepCompleted =
    progress.currentStep.item.name === 'completed';
  const isCheckoutStepReview = progress.currentStep.item.name === 'review';
  const showOrderReviewPage =
    (isCheckoutStepCompleted && cartState !== 'pending') ||
    isCheckoutStepReview;

  /* TODO: Move <WeblinkNavigation /> further up the component tree so that the
  Checkout does not need to be responsible for rendering
  it https://administrate.atlassian.net/browse/PLAT-11827 */

  useEffect(() => {
    if (showOrderReviewPage && redirectUrl) {
      window.location = portalStore.postCheckoutRedirectUrl;
    }
  }, [showOrderReviewPage, redirectUrl]);

  if (showOrderReviewPage && redirectUrl) {
    return (
      <>
        <WeblinkNavigation type="basic" disabled />
        <LoadingScreen message={t('weblink:paymentLoading')} />
      </>
    );
  }

  return (
    <>
      <WeblinkNavigation
        type="basic"
        back={{
          label: t('weblink:backToCatalogue'),
          onClick: () => {
            changeIsError(false);
            const redirectSite = portalStore.embedSite;
            if (redirectSite) {
              window.location = redirectSite;
            } else {
              toCatalogue();
            }
          },
        }}
      />
      <StickyContainer>
        <div className="checkout_loading-bar">
          <Sticky>
            {({ style }) => (
              <div style={{ ...style, zIndex: 1 }}>
                <LoadingBar
                  isLoading={isLoading || isApplyPromotionCodeLoading}
                />
              </div>
            )}
          </Sticky>
        </div>
        <main>
          {cartSize === 0 &&
          !isCheckoutStepCompleted &&
          !isCheckoutStepReview &&
          !isLoading ? (
            <ReturnToCatalogue toCatalogue={toCatalogue} />
          ) : (
            <>
              <CheckoutProgress
                currentStep={progress.currentStep.index}
                path={checkoutProgressPath}
                isLoading={isLoading}
              />
              {showNotice && (isCheckoutStepCompleted || isError) && (
                <Container>
                  <Container.Row>
                    <Container.Col lg={10} lgOffset={1} md={12}>
                      <div className="mt-4">
                        <Notice
                          body={
                            isError ? (
                              <ErrorNoticeContent
                                id={getDomainId(id)}
                                learnerAlreadyRegisteredError={
                                  learnerAlreadyRegisteredError
                                }
                                noRemainingSeatsError={noRemainingSeatsError}
                                checkoutError={checkoutError}
                                backToCart={backToCart}
                                defaultErrorMessage={defaultErrorMessage}
                                integration={integration}
                              />
                            ) : (
                              <SuccessNoticeContent />
                            )
                          }
                          type={isError ? 'danger' : 'success'}
                        />
                      </div>
                    </Container.Col>
                  </Container.Row>
                </Container>
              )}
              <div className="checkout">
                {showOrderReviewPage ? (
                  <ReviewOrder />
                ) : (
                  <CheckoutSteps
                    embedSite={portalStore.embedSite}
                    cartState={cartState}
                    t={t}
                    isLoading={isLoading}
                    progress={progress}
                    integration={integration}
                    isApplyPromotionCodeLoading={isApplyPromotionCodeLoading}
                    isCheckoutLoading={isCheckoutLoading}
                    applyPromotionCode={applyPromotionCode}
                    applyGiftVoucher={applyGiftVoucher}
                    giftVouchersEnabled={giftVouchersEnabled}
                    reloadCart={reloadCart}
                    reservationsValidUntil={reservationsValidUntil}
                    handleCartExpiry={handleCartExpiry}
                    backToCart={backToCart}
                  />
                )}
              </div>
            </>
          )}
        </main>
      </StickyContainer>
    </>
  );
};

Checkout.propTypes = {
  t: PropTypes.func.isRequired,
  integration: PropTypes.bool.isRequired,
};

export default withTranslation()(
  inject(
    STORE_ANALYTICS,
    STORE_CHECKOUT,
    STORE_CART,
    STORE_REVIEW_ORDER,
    STORE_STORE,
    STORE_NAVIGATION,
  )(withIntegration(observer(Checkout))),
);
