import {
  decorate,
  action,
  observable,
  computed,
  intercept,
  reaction,
  flow,
  runInAction,
} from 'mobx';

import { get } from 'lodash';
import * as Sentry from '@sentry/react';
import queries from '../queries';
import Cart from './Cart';
import Learner from './Learner';
import GiftVoucherRecipient from './GiftVoucherRecipient';
import { MAX_SEATS } from '../constants';
import BaseStore from './baseStore';
import bundleConfig from '../bundleConfig';
import portalStore from './portalStore';
import configStore from './configStore';
import { portalTokenStore, cartRetrievalStore } from './localStorage';
import alertStore from './alertStore';
import { isCartAuthError } from '../graphql/apolloClientUtils';
import {
  UnauthorizedCartRetrievalError,
  InvalidRetrievalTokenError,
  INVALID_TOKEN_CART_RETRIEVAL_MESSAGE,
  UNAUTHORIZED_CART_RETRIEVAL_MESSAGE,
} from './errors/cart';
import {
  mapValuesArrayToObject,
  mapValuesObjectToArray,
} from '../utils/customFields';
import { checkForMutationError } from '../utils/errors';
import { CartEvent } from '../analytics';

const { cart: cartQueries } = queries;

const CART_RETRIEVAL_ERROR_MAP = {
  [UNAUTHORIZED_CART_RETRIEVAL_MESSAGE]: UnauthorizedCartRetrievalError,
  [INVALID_TOKEN_CART_RETRIEVAL_MESSAGE]: InvalidRetrievalTokenError,
};

export const CANNOT_EDIT_CART_MESSAGE = 'cannot be edited';

class EventAvailablePlacesCheckError extends Error {
  constructor(eventId, errors) {
    super(
      `Cannot check available places for Event ID ${eventId} available places: ${errors}`,
    );
    this.errors = errors;
  }
}

class ClaimCartError extends Error {
  constructor(cartId, errors) {
    const firstErrorMsg = errors && errors.length && errors[0].message;
    super(`Cannot claim cart ${cartId}: ${firstErrorMsg}`);
    this.errors = errors;
  }
}

class CartStore extends BaseStore {
  constructor(rootStore) {
    super(rootStore);
    this.resetCartType();
    this.resetProperties();
    this.resetCart = this.resetCart.bind(this);
  }

  initialize = async isAuthenticated => {
    await this.resetCart(isAuthenticated);
    this.getRetrievedCart = this.getRetrievedCart.bind(this);
    this.updateCartRecoveryToken = this.updateCartRecoveryToken.bind(this);
    runInAction(() => {
      this.isCartExpired = false;
    });
  };

  resetProperties = action(() => {
    this.cartStoreDisposer();
    if (this.usedCart === 'shoppingCart' || !this.shoppingCart) {
      this.shoppingCart = new Cart({});
    }
    this.buyNowCart = new Cart({});
    this.retrievedCart = new Cart({});
    this.eventPickerItem = null;
    this.showIncompleteNotice = false;
    this.isError = false;
    this.error = null;
    this.noRemainingSeatsError = false;
    this.checkoutError = null;
    this.disableCheckout = false;
    this.cartCreator = null;
    this.submittedPromotionCode = null;
    this.submittedGiftVoucher = null;
    this.isApplyPromotionCodeLoading = false;
    this.cartlessCheckoutIsUpdating = false;
    this.useRetrievedCart = this.usedCart === 'retrievedCart';
    this.cartItemToRebook = null;

    this.actions = {
      updateItemQuantity: this.updateItemQuantity,
    };
  });

  resetCartType = action(() => {
    this.usedCart = 'shoppingCart';
    if (
      window.localStorage.getItem(
        `weblink:${portalStore.portalAuthDomain}:buyNowCartId`,
      )
    ) {
      this.usedCart = 'buyNowCart';
      return;
    }
    if (window.localStorage.getItem('weblink:cartRecoveryToken')) {
      this.usedCart = 'retrievedCart';
    }
  });

  setError = action('setError', value => {
    this.error = value;
  });

  setIsApplyPromotionCodeLoading = action(
    'setIsApplyPromotionCodeLoading',
    value => {
      this.isApplyPromotionCodeLoading = value;
    },
  );

  setSubmittedPromotionCode = action('setSubmittedPromotionCode', value => {
    this.submittedPromotionCode = value;
  });

  setCartItemToRebook = action('setCartItemToRebook', value => {
    this.cartItemToRebook = value;
  });

  setSubmittedGiftVoucher = action('setSubmittedGiftVoucher', value => {
    this.submittedGiftVoucher = value;
  });

  updateCartRecoveryToken = action(
    'updateCartRecoveryToken',
    cartRecoveryToken => {
      window.localStorage.setItem(
        'weblink:cartRecoveryToken',
        cartRecoveryToken,
      );
      this.usedCart = 'retrievedCart';
      this.resetCart();
    },
  );

  updateCartWithAPIResponse = responseCart => {
    // Ensures that the Buyer and Learner details entered locally persist after applying the Promo Code
    responseCart.buyerDetails = this.cart.buyerDetails;
    const currentLearnerDetails = {};
    this.cart.items.forEach(item => {
      currentLearnerDetails[item.id] = item.learners;
    });

    this.setCart(Cart.fromAPIResponse(responseCart, this.actions));

    this.cart.items.forEach(item => {
      item.learners = currentLearnerDetails[item.id];
    });
  };

  applyPromotionCode = action('applyPromotionCode', async promotionCode => {
    this.setIsApplyPromotionCodeLoading(true);
    const cartId = await this.cartId;
    const {
      data: { cart },
    } = await this.mutateCart({
      mutation: queries.cart.applyPromotionCode,
      variables: {
        cartId,
        promotionCode,
      },
    });

    this.setIsApplyPromotionCodeLoading(false);
    if (
      cart.applyPromotionCode.errors &&
      cart.applyPromotionCode.errors.length
    ) {
      return {
        success: false,
        message: cart.applyPromotionCode.errors[0].message,
      };
    }

    this.updateCartWithAPIResponse(cart.applyPromotionCode.cart);

    // This fixes the case where a 100% promo code applied on the payment page shows an empty order review page
    if (this.cart.price.grandTotal === '0.00') {
      this.rootStore.checkoutStore.populateReviewStore(this.cart);
    }
    this.setSubmittedPromotionCode(promotionCode);

    return {
      success: true,
      discountTotal: this.cart.price.discountTotal,
      promotionCode,
    };
  });

  removePromotionCode = action('removePromotionCode', async promotionCode => {
    this.setIsApplyPromotionCodeLoading(true);
    const cartId = await this.cartId;
    const {
      data: { cart },
    } = await this.mutateCart({
      mutation: queries.cart.removePromotionCode,
      variables: {
        cartId,
        promotionCode,
      },
    });
    this.setIsApplyPromotionCodeLoading(false);

    if (
      cart.removePromotionCode.errors &&
      cart.removePromotionCode.errors.length
    ) {
      return {
        success: false,
        message: cart.removePromotionCode.errors[0].message,
      };
    }

    const previousGrandTotal = this.cart.price.grandTotal;

    this.updateCartWithAPIResponse(cart.removePromotionCode.cart);

    // This fixes the case where a 100% promo code applied on the payment page shows an empty order review page
    if (
      previousGrandTotal === '0.00' &&
      this.cart.price.grandTotal !== '0.00'
    ) {
      this.rootStore.checkoutStore.populateReviewStore(this.cart);
    }
    this.setSubmittedPromotionCode(promotionCode);

    return {
      success: true,
    };
  });

  applyGiftVoucher = action('applyGiftVoucher', async code => {
    this.setIsApplyPromotionCodeLoading(true);
    const cartId = await this.cartId;
    const {
      data: { cart },
    } = await this.mutateCart({
      mutation: queries.cart.applyGiftVoucher,
      variables: {
        cartId,
        code,
      },
    });

    this.setIsApplyPromotionCodeLoading(false);
    if (cart.applyGiftVoucher.errors && cart.applyGiftVoucher.errors.length) {
      return {
        success: false,
        message: cart.applyGiftVoucher.errors[0].message,
        label: cart.applyGiftVoucher.errors[0].label,
      };
    }

    this.updateCartWithAPIResponse(cart.applyGiftVoucher.cart);

    this.setSubmittedGiftVoucher(code);

    return {
      success: true,
    };
  });

  refreshCart = action('refreshCart', cart => {
    const updatedCart = Cart.fromAPIResponse(cart, this.actions);
    this.setCart(updatedCart);
    return updatedCart;
  });

  reloadCart = action('reloadCart', async () => {
    this.changeIsLoading(true);
    const reloadedCart = await this.getOriginalCart();
    this.setCart(reloadedCart);
    this.changeIsLoading(false);
  });

  handleCartExpiry = action('handleCartExpiry', value => {
    this.isCartExpired = value;
    if (this.isCartExpired) {
      this.rootStore.checkoutStore.resetProgress();
    }
  });

  unapplyGiftVoucher = action('unapplyGiftVoucher', async code => {
    this.setIsApplyPromotionCodeLoading(true);
    const cartId = await this.cartId;
    const {
      data: { cart },
    } = await this.mutateCart({
      mutation: queries.cart.unapplyGiftVoucher,
      variables: {
        cartId,
        code,
      },
    });

    this.setIsApplyPromotionCodeLoading(false);
    if (
      cart.unapplyGiftVoucher.errors &&
      cart.unapplyGiftVoucher.errors.length
    ) {
      return {
        success: false,
        message: cart.unapplyGiftVoucher.errors[0].message,
      };
    }

    this.updateCartWithAPIResponse(cart.unapplyGiftVoucher.cart);

    this.setSubmittedGiftVoucher(null);

    return {
      success: true,
    };
  });

  changeIsError = action('changeIsError', value => {
    this.isError = value;
  });

  setNoRemainingSeatsError = action('setNoRemainingSeatsError', value => {
    this.noRemainingSeatsError = value;
  });

  setCheckoutError = action('setCheckoutError', value => {
    this.checkoutError = value;
  });

  determineNoRemainingSeatsError = action(
    'determineNoRemainingSeatsError',
    () => {
      const noRemainingSeatsString =
        'Event does not have enough space to make this reservation';
      let noRemainingSeatsError;

      if (this.error.find) {
        noRemainingSeatsError =
          this.error.find(error => error.message === noRemainingSeatsString) ||
          false;
      } else {
        noRemainingSeatsError =
          this.error.message === noRemainingSeatsString ? this.error : false;
      }

      if (noRemainingSeatsError) {
        const errorItem = this.cart.items.find(
          item => item.itemId === this.error.value,
        );
        if (errorItem) {
          noRemainingSeatsError.title = errorItem.course;
        }
      }

      this.noRemainingSeatsError = noRemainingSeatsError;
    },
  );

  determineLearnerAlreadyRegisteredError = action(
    'determineLearnerAlreadyRegisteredError',
    () => {
      const learnerAlreadyRegisteredString =
        'There is already a registration for this learner';
      let learnerAlreadyRegisteredError;

      if (this.error.find) {
        learnerAlreadyRegisteredError =
          this.error.find(error =>
            error.message.includes(learnerAlreadyRegisteredString),
          ) || false;
      } else {
        learnerAlreadyRegisteredError =
          this.error.message === learnerAlreadyRegisteredString
            ? this.error
            : false;
      }

      if (learnerAlreadyRegisteredError) {
        const errorItem = this.cart.items.find(
          item => item.itemId === this.error.value,
        );
        if (errorItem) {
          learnerAlreadyRegisteredError.title = errorItem.course;
        }
      }

      this.learnerAlreadyRegisteredError = learnerAlreadyRegisteredError;
    },
  );

  changeDisableCheckout = action('changeDisableCheckout', value => {
    this.disableCheckout = value;
  });

  changeIsLoading = action('changeIsLoading', value => {
    if (value) {
      this.loader.start();
    } else {
      this.loader.stop();
    }
  });

  setBuyNowCart = action('setBuyNowCart', buyNowCart => {
    this.buyNowCart = buyNowCart;
  });

  setRetrievedCart = action('setRetrievedCart', retrievedCart => {
    this.retrievedCart = retrievedCart;
  });

  get isLoading() {
    return this.loader.isLoading;
  }

  async getOriginalCart(isAuthenticated) {
    const { cartIdFromLocalStorage } = this;
    Sentry.setTag('cartId', cartIdFromLocalStorage);
    if (!cartIdFromLocalStorage) {
      return null;
    }
    if (this.usedCart === 'retrievedCart') {
      return this.getRetrievedCart();
    }
    if (isAuthenticated) {
      return this._claimCart(cartIdFromLocalStorage);
    }
    try {
      const {
        data: { cart },
      } = await this.apolloClient.query({
        query: queries.cart.getCartById,
        variables: {
          id: cartIdFromLocalStorage,
        },
        fetchPolicy: 'no-cache',
      });
      return Cart.fromAPIResponse(cart, this.actions);
    } catch (e) {
      if (isCartAuthError(e)) {
        return null;
      }
      throw e;
    }
  }

  async _claimCart(cartId) {
    const guestToken = portalTokenStore.getPortalToken(
      portalStore.portalAuthDomain,
    );
    const {
      data: {
        cart: {
          claimCart: { cart, errors },
        },
      },
    } = await this.mutateCart({
      mutation: queries.cart.claimCart,
      variables: { cartId, guestToken },
    });
    if (errors && errors.length) {
      throw new ClaimCartError(cartId, errors);
    }
    return Cart.fromAPIResponse(cart, this.actions);
  }

  async getShoppingCart() {
    const { shoppingCartIdFromLocalStorage } = this;
    if (!shoppingCartIdFromLocalStorage) {
      return new Cart({});
    }

    return this._queryCartById(shoppingCartIdFromLocalStorage);
  }

  async getRetrievedCart() {
    const claimedRetrievedCartId = cartRetrievalStore.getRetrievedCartId();
    if (claimedRetrievedCartId) {
      return this._queryCartById(claimedRetrievedCartId);
    }

    const token = this.retrievedCartIdFromLocalStorage;
    const {
      data: {
        cart: {
          claimCartWithRecoveryToken: { cart: cartResponse, errors },
        },
      },
    } = await this.mutateCart({
      mutation: queries.cart.claimCartWithRecoveryToken,
      variables: { token },
    });
    const ErrorClass = CART_RETRIEVAL_ERROR_MAP[get(errors, '0.message')];
    if (ErrorClass) {
      throw new ErrorClass();
    }
    return Cart.fromAPIResponse(cartResponse, this.actions);
  }

  async _queryCartById(cartId) {
    const {
      data: { cart },
    } = await this.apolloClient.query({
      query: queries.cart.getCartById,
      variables: {
        id: cartId,
      },
      fetchPolicy: 'no-cache',
    });
    return Cart.fromAPIResponse(cart, this.actions);
  }

  async mutateCart(options) {
    const handleCartCannotBeEditedError = () => {
      window.localStorage.removeItem(
        `weblink:${portalStore.portalAuthDomain}:cartId`,
      );
      runInAction(() => {
        this.changeIsLoading(false);
        this.setError({ message: CANNOT_EDIT_CART_MESSAGE });
        this.changeIsError(true);
      });
    };

    let response;
    try {
      response = await this.apolloClient.mutate(options);
    } catch (e) {
      if (e.message && e.message.includes(CANNOT_EDIT_CART_MESSAGE)) {
        handleCartCannotBeEditedError();
      }
      throw e;
    }

    if (checkForMutationError(response, CANNOT_EDIT_CART_MESSAGE)) {
      handleCartCannotBeEditedError();
    }
    return response;
  }

  resetCart = flow(async function* initializeCartStore(isAuthenticated) {
    this.loader.start();
    try {
      this.resetCartType();
      const originalCart = yield this.getOriginalCart(isAuthenticated);
      this.resetProperties();

      // Get cartId from localStorage
      if (originalCart) {
        if (originalCart.state === 'locked') {
          window.localStorage.removeItem(
            `weblink:${portalStore.portalAuthDomain}:cartId`,
          );
          this.resetCart();
          return;
        }

        // If won, populate review order, empty localStorage
        if (
          originalCart.state === 'won' ||
          originalCart.state === 'orderReview'
        ) {
          this.rootStore.checkoutStore.populateReviewStore(originalCart);
          switch (this.usedCart) {
            case 'shoppingCart': {
              window.localStorage.removeItem(
                `weblink:${portalStore.portalAuthDomain}:cartId`,
              );
              break;
            }
            case 'buyNowCart': {
              this.changeUsedCart('shoppingCart');
              break;
            }
            case 'retrievedCart': {
              cartRetrievalStore.removeRetrievedCartId();
              this.changeUsedCart('shoppingCart');
              break;
            }
            default:
          }
        } else {
          this.setCart(originalCart);
        }

        if (this.usedCart === 'retrievedCart') {
          this.retrievedCart = await this.getRetrievedCart();
          cartRetrievalStore.setRetrievedCartId(this.retrievedCart.id);
        }

        if (originalCart.state === 'won') {
          this.rootStore.checkoutStore.progress.setCompleted();
          return;
        }

        if (
          ['pending', 'orderRetrieval', 'orderReview'].includes(
            originalCart.state,
          )
        ) {
          const { isFree } = this.rootStore.reviewOrderStore;

          let lastStep = 3;

          /*
           * For portals which have require learner details turned off, we need to reduce the number of checkout steps while
           * the checkout is in progress. Once we hit the won state, the checkout breadcrumbs always include the learner details step
           * regardless of the value of this setting so we need to hard code it back to 3 again as there are 4 steps (zero-indexed).
           */
          if (!this.rootStore.storeStore.requireLearnerDetails) {
            const hasGiftVoucher =
              originalCart.items.filter(item => item.isGiftVoucher).length > 0;
            if (!hasGiftVoucher) lastStep = 2;
          }

          if (isFree || originalCart.state === 'orderReview') {
            lastStep -= 1;
          }
          if (this.useRetrievedCart) {
            this.useRetrievedCart = false;
            lastStep -= 2;
          }

          this.rootStore.checkoutStore.progress.setStep(lastStep);
        }
      }

      this.shoppingCartInterceptDisposer = intercept(
        this,
        'shoppingCart',
        change => {
          if (change.newValue !== this.shoppingCart) {
            this.shoppingCart.dispose();
          }
          return change;
        },
      );

      this.buyNowCartInterceptDisposer = intercept(
        this,
        'buyNowCart',
        change => {
          if (change.newValue !== this.buyNowCart) {
            this.buyNowCart.dispose();
          }
          return change;
        },
      );

      this.retrievedCartInterceptDisposer = intercept(
        this,
        'retrievedCart',
        change => {
          if (change.newValue !== this.retrievedCart) {
            this.retrievedCart.dispose();
          }
          return change;
        },
      );

      this.errorReactionDisposer = reaction(
        () => this.error,
        () => {
          this.determineNoRemainingSeatsError();
          this.determineLearnerAlreadyRegisteredError();
        },
      );
    } catch (err) {
      this.changeIsError(true);
      this.setError(err);
    } finally {
      this.loader.stop();
    }
  });

  get cart() {
    return this[this.usedCart];
  }

  set cart(cart) {
    this[this.usedCart] = cart;
  }

  moveCartToEntry = action('moveCartToEntry', async cartId => {
    this.changeIsLoading(true);

    const { data } = await this.mutateCart({
      mutation: queries.cart.moveCartToEntryStep,
      variables: {
        cartId,
      },
    });

    const { errors } = data.cart.moveCartToEntryStep;
    if (errors && errors.length > 0) {
      return;
    }

    this.setCart(
      Cart.fromAPIResponse(data.cart.moveCartToEntryStep.cart, this.actions),
    );

    this.changeIsLoading(false);
  });

  changeUsedCart = action('changeUsedCart', value => {
    if (value === 'shoppingCart') {
      window.localStorage.removeItem(
        `weblink:${portalStore.portalAuthDomain}:buyNowCartId`,
      );
      window.localStorage.removeItem('weblink:cartRecoveryToken');
      this.buyNowCart = new Cart({});
      this.retrievedCart = new Cart({});
    }
    this.usedCart = value;
  });

  cartStoreDisposer = action('cartStoreDisposer', () => {
    if (this.shoppingCartInterceptDisposer) {
      this.shoppingCartInterceptDisposer();
    }
    if (this.buyNowCartInterceptDisposer) {
      this.buyNowCartInterceptDisposer();
    }
    if (this.retrievedCartInterceptDisposer) {
      this.retrievedCartInterceptDisposer();
    }
    if (this.errorReactionDisposer) {
      this.errorReactionDisposer();
    }
  });

  get cartIdFromLocalStorage() {
    switch (this.usedCart) {
      case 'shoppingCart':
        return this.shoppingCartIdFromLocalStorage;
      case 'buyNowCart':
        return window.localStorage.getItem(
          `weblink:${portalStore.portalAuthDomain}:buyNowCartId`,
        );
      case 'retrievedCart':
        return this.retrievedCartIdFromLocalStorage;
      default:
        return null;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  get shoppingCartIdFromLocalStorage() {
    return window.localStorage.getItem(
      `weblink:${portalStore.portalAuthDomain}:cartId`,
    );
  }

  // eslint-disable-next-line class-methods-use-this
  get retrievedCartIdFromLocalStorage() {
    return window.localStorage.getItem('weblink:cartRecoveryToken');
  }

  get cartId() {
    // Store
    if (this.cart.id) {
      return Promise.resolve(this.cart.id);
    }

    // LocalStorage
    const { cartIdFromLocalStorage } = this;
    if (cartIdFromLocalStorage) {
      return Promise.resolve(cartIdFromLocalStorage);
    }

    if (this.usedCart === 'retrievedCart') {
      throw new Error('Retrieved Cart not Found');
    }

    // Create new
    this.cartCreator = this.createCart();
    this.cartCreator.then(cart => {
      if (cart) {
        this.setCart(Cart.fromAPIResponse(cart, this.actions));
        window.localStorage.setItem(
          `weblink:${portalStore.portalAuthDomain}:${
            this.usedCart === 'shoppingCart' ? 'cartId' : 'buyNowCartId'
          }`,
          cart.id,
        );
      }
    });
    return this.cartCreator.then(cart => (cart ? cart.id : null));
  }

  createCart = action('createCart', async () => {
    const {
      data: {
        cart: {
          createCart: { errors, cart },
        },
      },
    } = await this.apolloClient.mutate({
      mutation: cartQueries.createCart,
    });

    if (errors.length) {
      this.changeIsError(true);
      this.setError(errors);
      return null;
    }
    return cart;
  });

  createBuyNowCartAndGoToCheckout = action(
    'createBuyNowCartAndGoToCheckout',
    async (item, selectedPathObjectives) => {
      this.rootStore.detailsStore.setLoadingState(true);
      this.changeUsedCart('buyNowCart');
      const cartId = await this.cartId; // create the new cart
      await this.addToCart(
        item,
        'pathId' in item ? selectedPathObjectives : undefined,
      );
      if (!this.rootStore.embededNavigation) {
        this.rootStore.navigationStore.toCheckout();
        this.rootStore.detailsStore.setLoadingState(false);
      } else {
        const { locale } = configStore;
        const portalToken = portalTokenStore.getPortalToken(
          bundleConfig.portalAuthDomain,
        );
        window.location = `https://${
          bundleConfig.portalAuthDomain
        }/checkout?portalToken=${portalToken}&cartID=${cartId}&originalSite=${
          window.location.origin
        }${locale ? `&locale=${locale}` : ''}`;
      }
    },
  );

  backToCart = action('backToCart', () => {
    this.changeIsError(false);
    this.setError(null);
    this.learnerAlreadyRegisteredError = false;
    this.setNoRemainingSeatsError(false);
    return this.rootStore.navigationStore.toCart();
  });

  setCart = action('setCart', value => {
    this.cart = value;
  });

  replaceItem = action('replaceItem', async ({ removeId, addItem }) => {
    await this.removeFromCart(removeId);
    return this.addToCart(addItem);
  });

  _addToCartMutation = async mutationVariables => {
    const {
      data: {
        cart: {
          addLineItem: { cart, errors },
        },
      },
    } = await this.mutateCart({
      mutation: queries.cart.addLineItem,
      variables: mutationVariables,
    });
    return { cart, errors };
  };

  _rebookOrUpdateItem = async (cartItem, storeItem) => {
    if (cartItem.isExpired) {
      this.rebookCartItem(cartItem.id, storeItem.quantity);
    } else {
      this.changeSeats({
        row: cartItem,
        value: cartItem.quantity + storeItem.quantity,
      });
    }
  };

  addToCart = action('addToCart', async (storeItem, selectedPathObjectives) => {
    this.changeIsLoading(true);
    this.changeIsError(false);
    const isPath = !!storeItem.pathId;
    const productId = isPath ? storeItem.pathId : storeItem.id;

    const cartItem = isPath
      ? this.getPathItemFromCart(productId, selectedPathObjectives, storeItem)
      : this.getEventItemFromCart(productId, storeItem);

    if (cartItem) {
      this._rebookOrUpdateItem(cartItem, storeItem);
      this.changeIsLoading(false);
      return;
    }
    const cartId = await this.cartId;
    const { cart, errors } = await this._addToCartMutation({
      cartId,
      productId,
      quantity: storeItem.quantity,
      learnerDetails: [],
      priceLevelId: get(storeItem, 'priceLevel.id', null),
      objectives: isPath ? selectedPathObjectives : undefined,
    });

    if (errors.length) {
      this.setError(errors);
      this.changeIsError(true);
      this.changeIsLoading(false);
      return;
    }
    if (this.rootStore.checkoutStore.progress.currentStep.index > 0) {
      this.rootStore.checkoutStore.updateCurrentItemIndex(0);
      this.rootStore.checkoutStore.changeStep(1);
    }
    const updatedCart = this.refreshCart(cart);
    const newCartItem = updatedCart.items.find(
      ({ eventId }) => eventId === productId,
    );
    this.rootStore.analyticsStore.captureEvent(
      CartEvent.fromCartAdd({ cart: updatedCart, cartItem: newCartItem }),
    );
    this.changeIsLoading(false);
  });

  getEventItemFromCart = (eventId, storeItem) =>
    this.cart.items.find(
      i =>
        i.eventId === eventId &&
        get(i, 'priceLevel.id') === get(storeItem, 'priceLevel.id'),
    );

  getPathItemFromCart = (pathId, selectedPathObjectives, storeItem) => {
    const objectivesMatch = (cartObjectives, pathObjectives) => {
      const cartObjectivesEventsIds = cartObjectives
        .map(o => o.event.id)
        .sort();
      const pathObjectivesEventsIds = pathObjectives.map(o => o.eventId).sort();
      const allEqual =
        JSON.stringify(cartObjectivesEventsIds) ===
        JSON.stringify(pathObjectivesEventsIds);
      return allEqual;
    };

    return this.cart.items.find(
      i =>
        i.eventId === pathId &&
        objectivesMatch(i.objectives, selectedPathObjectives) === true &&
        get(i, 'priceLevel.id') === get(storeItem, 'priceLevel.id'),
    );
  };

  addGiftVoucherToCart = action('addGiftVoucherToCart', async item => {
    this.changeIsLoading(true);
    this.changeIsError(false);
    const cartId = await this.cartId;

    const {
      data: {
        cart: {
          addGiftVoucherLineItem: { cart, errors },
        },
      },
    } = await this.mutateCart({
      mutation: queries.cart.addGiftVoucherLineItem,
      variables: {
        cartId,
        productOptionId: item.giftVoucherId,
        amount: item.amount,
      },
    });

    this.changeIsLoading(false);

    if (errors.length) {
      this.setError(errors);
      this.changeIsError(true);
      return;
    }

    if (this.rootStore.checkoutStore.step > 0) {
      this.rootStore.checkoutStore.updateCurrentItemIndex(0);
      this.rootStore.checkoutStore.changeStep(1);
    }

    const updatedCart = this.refreshCart(cart);
    const newCartItem = updatedCart.items.slice(-1).pop();
    this.rootStore.analyticsStore.captureEvent(
      CartEvent.fromCartAdd({ cart: updatedCart, cartItem: newCartItem }),
    );
  });

  clearCart = action('clearCart', async () => {
    await Promise.all(this.cart.items.map(i => this.removeFromCart(i.id)));
  });

  _removeCartItem = async (cartId, itemId) => {
    const {
      data: {
        cart: {
          removeCartLineItem: { errors, cart },
        },
      },
    } = await this.mutateCart({
      mutation: queries.cart.removeCartLineItem,
      variables: {
        cartId,
        itemId,
      },
    });
    return { errors, cart };
  };

  removeFromCart = action('removeFromCart', async itemId => {
    this.changeIsLoading(true);
    this.changeIsError(false);

    const cartId = await this.cartId;
    const { errors, cart } = await this._removeCartItem(cartId, itemId);
    const index = this.cart.items.findIndex(item => item.id === itemId);
    const removedItem = this.cart.items[index];

    if (errors.length) {
      this.changeIsError(true);
      this.setError(errors);
      this.changeIsLoading(false);
      return;
    }

    this.rootStore.checkoutStore.updateCurrentItemIndex(0);
    this.rootStore.checkoutStore.changeStep(0);
    const updatedCart = this.refreshCart(cart);
    this.rootStore.analyticsStore.captureEvent(
      CartEvent.fromCartRemove({ cart: updatedCart, cartItem: removedItem }),
    );
    this.changeIsLoading(false);
  });

  _canRebookEvent = async (eventId, requiredPlaces) => {
    const { errors, data } = await this.apolloClient.query({
      query: queries.event.getEventAvailablePlaces,
      variables: {
        eventId,
      },
      fetchPolicy: 'network-only',
    });
    const edges = get(data, 'events.edges');
    if (errors || !edges) {
      throw new EventAvailablePlacesCheckError(eventId, errors);
    }
    if (!edges.length) {
      // weblink-api does not return an event at all if it has no spaces available
      return false;
    }
    const [{ node }] = edges;
    return (
      node.remainingPlaces === null || node.remainingPlaces >= requiredPlaces
    );
  };

  // eslint-disable-next-line class-methods-use-this
  isItemRebookable = cartItem =>
    cartItem.isExpired &&
    (cartItem.remainingPlaces === null ||
      cartItem.remainingPlaces >= cartItem.quantity);

  rebookCartItem = action(
    'rebookCartItem',
    async (cartItemId, overrideQuantity = null) => {
      function _error(self, error) {
        self.setError(error);
        self.changeIsError(true);
        self.changeIsLoading(false);
      }
      this.changeIsLoading(true);
      this.changeIsError(false);
      const cartId = await this.cartId;
      const cartItem = this.cart.getCartItemById(cartItemId);
      const rebookedItemQuantity = overrideQuantity || cartItem.quantity;

      let canRebook = false;

      try {
        if (cartItem.isPath) {
          const eventObjectives = cartItem.objectives.filter(x => x.event);
          const canRebookEventObjectives = await Promise.all(
            eventObjectives.map(o =>
              this._canRebookEvent(o.event.id, rebookedItemQuantity),
            ),
          );
          canRebook = canRebookEventObjectives.every(
            canRebookObjective => canRebookObjective,
          );
        } else {
          canRebook = await this._canRebookEvent(
            cartItem.eventId,
            rebookedItemQuantity,
          );
        }
      } catch (e) {
        _error(this, e.errors);
      }

      if (!canRebook) {
        const cart = await this.getShoppingCart();
        this.setCart(cart);
        const updatedCartItem = this.cart.getCartItemById(cartItemId);
        this.setCartItemToRebook({
          id: updatedCartItem.id,
          name: updatedCartItem.course,
          bookedQuantity: updatedCartItem.quantity,
          remainingPlaces: updatedCartItem.remainingPlaces,
        });
        this.changeIsLoading(false);
        return;
      }

      this.setCartItemToRebook(null);

      const newInterestVariables = {
        cartId,
        productId: cartItem.isPath ? cartItem.pathId : cartItem.eventId,
        quantity: rebookedItemQuantity,
        priceLevelId: cartItem.priceLevel && cartItem.priceLevel.id,
        learnerDetails: [],
        objectives: cartItem.isPath
          ? cartItem.objectives
              .filter(o => o.event)
              .map(o => ({
                eventId: o.event.id,
                objectiveId: o.learningPathObjective.id,
              }))
          : undefined,
      };

      const {
        errors: removalErrors,
        cart: cartAfterRemoval,
      } = await this._removeCartItem(cartId, cartItemId);
      if (removalErrors && removalErrors.length) {
        _error(this, removalErrors);
        return;
      }

      const { cart, errors } = await this._addToCartMutation(
        newInterestVariables,
      );
      if (errors.length) {
        _error(this, errors);
        this.setCart(Cart.fromAPIResponse(cartAfterRemoval, this.actions));
        return;
      }

      this.setCart(Cart.fromAPIResponse(cart, this.actions));
      this.changeIsLoading(false);
    },
  );

  removeCartItemToRebookFromCart = action(
    'removeCartItemToRebookFromCart',
    async () => {
      if (!this.cartItemToRebook) {
        return;
      }

      await this.removeFromCart(this.cartItemToRebook.id);

      this.setCartItemToRebook(null);
    },
  );

  changeSeats = action('changeSeats', ({ row, value }) => {
    const remainingPlaces = get(row, 'remainingPlaces', null);
    const index = this.cart.items.findIndex(item => item.id === row.id);
    const cartItem = this.cart.items[index];

    let updatedValue = value;

    if (value > row.maxBookableQuantity) {
      updatedValue = row.maxBookableQuantity;
    }

    const minOf = [
      parseInt(updatedValue, 10),
      this.rootStore.maxQuantity || MAX_SEATS,
    ];

    const quantity = Math.min(...minOf) || null;
    const originalQuantity = cartItem.quantity;

    if (originalQuantity !== quantity) {
      this.cart.items[index].quantity = quantity;

      const shouldOptimisticallyUpdateRemainingPlaces =
        row.isReserved && this.cart.items[index].remainingPlaces !== null;
      if (shouldOptimisticallyUpdateRemainingPlaces) {
        const change = quantity - originalQuantity;
        const updatedRemainingPlaces = remainingPlaces - change;
        this.cart.items[index].remainingPlaces = updatedRemainingPlaces;
      }

      if (this.rootStore.checkoutStore.progress.currentStep.index > 0) {
        this.rootStore.checkoutStore.updateCurrentItemIndex(0);
        this.rootStore.checkoutStore.changeStep(1);
      }
    }
  });

  updateCartBuyerInformation = action(
    'updateCartBuyerInformation',
    async ({
      firstName,
      lastName,
      email,
      company,
      billingAddress,
      pointOfSaleOrderFields,
    }) => {
      this.changeIsLoading(true);
      this.changeIsError(false);
      const { locale } = configStore;
      const cartId = await this.cartId;
      const variables = {
        cartId,
        firstName,
        lastName,
        email,
        company,
      };
      if (billingAddress) {
        variables.billingAddress = billingAddress;
      }
      if (pointOfSaleOrderFields) {
        variables.pointOfSaleFields = pointOfSaleOrderFields;
      }
      if (locale) {
        variables.locale = locale;
      }

      try {
        const {
          data: {
            cart: { updateCart: updateCartResp },
          },
        } = await this.mutateCart({
          mutation: cartQueries.updateCart,
          variables,
        });
        if (updateCartResp.errors.length) {
          this.changeIsError(true);
          this.setError(updateCartResp.errors);
          this.changeIsLoading(false);
        } else {
          this.setCart(Cart.fromAPIResponse(updateCartResp.cart, this.actions));
          this.changeIsLoading(false);
        }
      } catch (err) {
        this.changeIsError(true);
        this.setError(err);
        this.changeIsLoading(false);
      }
    },
  );

  toggleUseBookerDetails = action('toggleUseBookerDetails', itemId => {
    const item = this.cart.items.find(i => i.id === itemId);
    const isCurrentlyUsingBookerDetails = item.usingBookerDetails;
    const currentLearner = item.learners[0];

    if (isCurrentlyUsingBookerDetails) {
      item.learners[0] = Learner.getEmptyLearner(currentLearner.id);
    } else {
      const {
        buyerDetails: {
          email,
          confirmedEmail,
          firstName,
          lastName,
          customFieldValues,
        },
      } = this.cart;
      item.learners[0] = new Learner({
        id: currentLearner.id,
        email,
        confirmedEmail,
        firstName,
        lastName,
        customFieldValues: mapValuesArrayToObject(customFieldValues),
      });
    }

    item.usingBookerDetails = !item.usingBookerDetails;
  });

  setBookerAsRecipient = action('setBookerAsRecipient', itemId => {
    const index = this.cart.items.findIndex(item => item.id === itemId);
    this.cart.items[index].recipientDetails = new GiftVoucherRecipient({
      email: this.cart.buyerDetails.email,
      confirmedEmail: this.cart.buyerDetails.confirmedEmail,
      name: `${this.cart.buyerDetails.firstName} ${this.cart.buyerDetails.lastName}`,
    });
  });

  clearRecipientDetails = action('clearRecipientDetails', item => {
    ['name', 'email', 'confirmedEmail'].forEach(property => {
      this.updateItemRecipientProperty({
        id: item.id,
        property,
        value: '',
      });
    });
  });

  updateItemLearnerProperty = action(
    'updateItemLearnerProperty',
    ({ id, learnerId, property, value }) => {
      const itemIndex = this.cart.items.findIndex(item => item.id === id);
      const { learners } = this.cart.items[itemIndex];
      const learnerIndex = learners.findIndex(
        learner => learner.id === learnerId,
      );
      this.cart.items[itemIndex].learners[learnerIndex].updateProperty({
        property,
        value,
      });
    },
  );

  updateItemLearnerEmail = action(
    'updateItemLearnerEmail',
    ({ id, learnerId, email, confirmEmails }) => {
      const itemIndex = this.cart.items.findIndex(item => item.id === id);
      const { learners } = this.cart.items[itemIndex];
      const learnerIndex = learners.findIndex(
        learner => learner.id === learnerId,
      );
      this.cart.items[itemIndex].learners[learnerIndex].updateEmail({
        email,
        confirmEmail: confirmEmails,
      });
    },
  );

  // eslint-disable-next-line class-methods-use-this
  _mapLearnerForUpdateMutation = (learner, pointOfSaleFieldDefinitions) => {
    const mappedLearner = {
      attributes: learner.customFieldValues
        ? mapValuesObjectToArray(
            learner.customFieldValues,
            pointOfSaleFieldDefinitions,
          )
        : null,
    };
    if (learner.isUnnamed) {
      return mappedLearner.attributes && mappedLearner.attributes.length
        ? mappedLearner
        : null;
    }
    return {
      email: learner.email,
      firstName: learner.firstName,
      lastName: learner.lastName,
      ...mappedLearner,
    };
  };

  updateItemRecipientProperty = action(
    'updateItemRecipientProperty',
    ({ id, property, value }) => {
      const itemIndex = this.cart.items.findIndex(item => item.id === id);
      this.cart.items[itemIndex].recipientDetails.updateProperty({
        property,
        value,
      });
    },
  );

  updateItemRecipientEmail = action(
    'updateItemRecipientEmail',
    ({ id, email, confirmEmails }) => {
      const itemIndex = this.cart.items.findIndex(item => item.id === id);
      this.cart.items[itemIndex].recipientDetails.updateEmail({
        email,
        confirmEmail: confirmEmails,
      });
    },
  );

  updateItemQuantity = async (cartItem, newQuantity, previousQuantity) => {
    if (!newQuantity) {
      return;
    }
    this.changeIsLoading(true);
    this.changeDisableCheckout(true);
    const cartId = await this.cartId;
    const {
      data: {
        cart: {
          updateEventLineItem: { cart, errors },
        },
      },
    } = await this.mutateCart({
      mutation: queries.cart.updateEventLineItem,
      variables: {
        input: {
          cartId,
          interestId: cartItem.id,
          quantity: Number(newQuantity),
          learnerDetails: cartItem.learners
            .filter(
              ({ email, firstName, lastName }) =>
                email && firstName && lastName,
            )
            .slice(0, Number(newQuantity))
            .map(({ email, firstName, lastName, customFieldValues }) => ({
              email,
              firstName,
              lastName,
              attributes: customFieldValues
                ? mapValuesObjectToArray(customFieldValues).filter(
                    ({ value }) => value !== null && value !== undefined,
                  )
                : null,
            })),
        },
      },
    });
    const updatedItemRaw = cart.items.find(({ id }) => id === cartItem.id);
    if (errors.length) {
      if (errors[0].label === 'notEnoughPlacesRemaining') {
        alertStore.setAlertMessage(
          `Sorry, there are not enough places remaining to increase the quantity above ${updatedItemRaw.quantity}.`,
          'warning',
        );
      } else {
        this.changeIsError(true);
        this.changeDisableCheckout(false);
        this.changeIsLoading(false);
        return;
      }
    }
    this.changeDisableCheckout(false);
    this.changeIsLoading(false);
    const updatedCart = this.refreshCart(cart);
    const updatedItem = updatedCart.items.find(({ id }) => id === cartItem.id);
    this.rootStore.analyticsStore.captureEvent(
      CartEvent.fromCartChange({
        cart: updatedCart,
        updatedItem,
        previousItemProps: { quantity: previousQuantity },
      }),
    );
  };

  addLearners = action('addLearners', async () => {
    const { requireLearnerDetails } = this.rootStore.storeStore;
    this.changeIsError(false);
    this.changeIsLoading(true);
    const cartId = await this.cartId;
    await Promise.all(
      this.cart.items
        .filter(item => !item.isGiftVoucher)
        .map(
          async ({
            id,
            pathId,
            learners,
            quantity,
            pointOfSaleDefinitions,
          }) => {
            let mutation = queries.cart.updateEventLineItem;
            if (pathId) {
              mutation = queries.cart.updatePathLineItem;
            }
            try {
              const learnerDetails = learners
                .filter(learner =>
                  learner.isValid(
                    pointOfSaleDefinitions,
                    requireLearnerDetails,
                  ),
                )
                .map(learner =>
                  this._mapLearnerForUpdateMutation(
                    learner,
                    pointOfSaleDefinitions,
                  ),
                )
                .filter(mappedLearner => !!mappedLearner);
              const {
                data: {
                  cart: {
                    updateEventLineItem: updateEventLineItemResp,
                    updatePathLineItem: CartMutateResponseType,
                  },
                },
              } = await this.mutateCart({
                mutation,
                variables: {
                  input: {
                    cartId,
                    interestId: id,
                    quantity: Number(quantity),
                    learnerDetails,
                  },
                },
              });
              if (
                CartStore.thereAreErrors(
                  updateEventLineItemResp,
                  CartMutateResponseType,
                )
              ) {
                this.changeIsError(true);
                if (
                  updateEventLineItemResp &&
                  updateEventLineItemResp.errors.length
                ) {
                  this.setError(updateEventLineItemResp.errors);
                }
                if (
                  CartMutateResponseType &&
                  CartMutateResponseType.errors.length
                ) {
                  this.setError(CartMutateResponseType.errors);
                }
              }
            } catch (err) {
              this.changeIsError(true);
              this.setError(err);
            }
          },
        ),
    );
    const {
      data: { cart },
    } = await this.apolloClient.query({
      query: queries.cart.getCartById,
      variables: {
        id: cartId,
      },
    });
    this.setCart(Cart.fromAPIResponse(cart, this.actions));
    this.changeIsLoading(false);
  });

  addGiftVoucherRecipient = action('addGiftVoucherRecipient', async item => {
    this.changeIsError(false);
    this.changeIsLoading(true);
    const cartId = await this.cartId;

    const mutation = queries.cart.updateGiftVoucherLineItem;
    const giftVoucherRecipientDetails = {
      name: item.recipientDetails.name,
      message: item.recipientDetails.message,
      emailConfigurationId: item.recipientDetails.emailConfigurationId,
      email: null,
      postalAddress: null,
    };

    if (
      item.recipientDetails &&
      item.recipientDetails.giftVoucherHasLinkedItem
    ) {
      giftVoucherRecipientDetails.postalAddress = {
        postalAddressLineOne: item.recipientDetails.postalAddressLineOne,
        postalAddressLineTwo:
          item.recipientDetails.postalAddressLineTwo || null,
        postalAddressTown: item.recipientDetails.postalAddressTown,
        postalAddressPostcode: item.recipientDetails.postalAddressPostcode,
        postalAddressCountryId: item.recipientDetails.postalAddressCountryId,
      };
    } else {
      giftVoucherRecipientDetails.email = item.recipientDetails.email;
    }

    try {
      const {
        data: {
          cart: { updateGiftVoucherLineItem: CartMutateResponseType },
        },
      } = await this.mutateCart({
        mutation,
        variables: {
          input: {
            cartId,
            interestId: item.id,
            giftVoucherRecipientDetails,
          },
        },
      });
      if (CartStore.thereAreErrors(CartMutateResponseType)) {
        this.changeIsError(true);
        if (CartMutateResponseType && CartMutateResponseType.errors.length) {
          this.setError(CartMutateResponseType.errors);
        }
      } else {
        this.setCart(
          Cart.fromAPIResponse(CartMutateResponseType.cart, this.actions),
        );
      }
    } catch (err) {
      this.changeIsError(true);
      this.setError(err);
    }
    this.changeIsLoading(false);
  });

  addPostage = action('addPostageFee', async item => {
    this.changeIsError(false);
    this.changeIsLoading(true);

    const mutation = queries.cart.addChildLineItem;
    const cartId = await this.cartId;

    try {
      const {
        data: {
          cart: { addChildLineItem: CartMutateResponseType },
        },
      } = await this.mutateCart({
        mutation,
        variables: {
          input: {
            cartId,
            productOptionId: item.giftVoucherConfigLinkedItem.id,
            parentId: item.id,
            amount: item.giftVoucherConfigLinkedItem.amount,
            quantity: 1,
          },
        },
      });
      if (CartStore.thereAreErrors(CartMutateResponseType)) {
        this.changeIsError(true);
        if (CartMutateResponseType && CartMutateResponseType.errors.length) {
          this.setError(CartMutateResponseType.errors);
        }
      } else {
        this.setCart(
          Cart.fromAPIResponse(CartMutateResponseType.cart, this.actions),
        );
      }
    } catch (err) {
      this.changeIsError(true);
      this.setError(err);
    }
    this.changeIsLoading(false);
  });

  removePostage = action('removePostageFee', async item => {
    this.changeIsError(false);
    this.changeIsLoading(true);

    const mutation = queries.cart.removeCartLineItem;

    try {
      const cartId = await this.cartId;
      const {
        data: {
          cart: { removeCartLineItem: CartMutateResponseType },
        },
      } = await this.mutateCart({
        mutation,
        variables: {
          cartId,
          itemId: item.childItems[0].id,
        },
      });
      if (CartStore.thereAreErrors(CartMutateResponseType)) {
        this.changeIsError(true);
        if (CartMutateResponseType && CartMutateResponseType.errors.length) {
          this.setError(CartMutateResponseType.errors);
        }
      } else {
        this.setCart(
          Cart.fromAPIResponse(CartMutateResponseType.cart, this.actions),
        );
      }
    } catch (err) {
      this.changeIsError(true);
      this.setError(err);
    }

    this.changeIsLoading(false);
  });

  prepareCartForCheckout = action('prepareCartForCheckout', async () => {
    this.changeIsLoading(true);
    this.changeIsError(false);
    const cartId = await this.cartId;
    try {
      const {
        data: {
          cart: { prepareForCheckout: prepareForCheckoutResp },
        },
      } = await this.mutateCart({
        mutation: cartQueries.prepareCartForCheckout,
        variables: { cartId },
      });
      if (prepareForCheckoutResp.errors.length) {
        this.changeIsError(true);
        this.setError(prepareForCheckoutResp.errors);
        this.changeIsLoading(false);
      } else {
        this.setCart(
          Cart.fromAPIResponse(prepareForCheckoutResp.cart, this.actions),
        );
        this.changeIsLoading(false);
      }
    } catch (err) {
      this.changeIsError(true);
      this.setError(err);
      this.changeIsLoading(false);
    }
  });

  static thereAreErrors(updateEventLineItemResp, CartMutateResponseType) {
    if (updateEventLineItemResp) {
      return updateEventLineItemResp.errors.length;
    }
    if (CartMutateResponseType) {
      return CartMutateResponseType.errors.length;
    }
    return false;
  }

  clearNotice = action(() => {
    this.showIncompleteNotice = false;
  });

  get cartSize() {
    return this.cart.items.length;
  }

  get containsValidItems() {
    return !this.isCartExpired || this.cart.containsGiftVoucher;
  }

  get cartSummaryList() {
    return this.cart.summaryList;
  }

  get shoppingCartSeatCount() {
    return this.cart.currentItems.reduce(
      (sum, item) => sum + Number(item.quantity),
      0,
    );
  }

  get reservationsValidUntil() {
    return this.cart.reservationsValidUntil;
  }

  closeEventPicker = action('closeEventPicker', () => {
    this.rootStore.eventPickerStore.close();
    this.eventPickerItem = null;
  });

  openEventPicker = action('openEventPicker', ({ eventPickerItem }) => {
    this.eventPickerItem = eventPickerItem;
    this.rootStore.eventPickerStore.open({
      prePopulated: eventPickerItem,
      title: 'weblink:editCourse',
      action: addItem => {
        this.replaceItem({ removeId: eventPickerItem.id, addItem }).then(() =>
          this.closeEventPicker(),
        );
      },
    });
    this.rootStore.eventPickerStore.setPrePopulated(eventPickerItem);
    this.rootStore.eventPickerStore.setCourseId(eventPickerItem.courseId);
  });

  buyerIsRecipient = item => {
    if (!item.recipientDetails) return false;

    const {
      firstName: buyerFirstName,
      lastName: buyerLastName,
      email: buyerEmail,
    } = this.cart.buyerDetails;
    const {
      name: recipientName,
      email: recipientEmail,
    } = item.recipientDetails;

    return (
      `${buyerFirstName} ${buyerLastName}` === recipientName &&
      buyerEmail === recipientEmail
    );
  };

  prepareItemsForCheckout = action('prepareItemsForCheckout', async () => {
    this.changeIsLoading(true);
    this.changeIsError(false);
    const cartId = await this.cartId;
    const {
      data: {
        cart: {
          prepareItemsForCheckout: { cart, errors },
        },
      },
    } = await this.mutateCart({
      mutation: cartQueries.prepareItemsForCheckout,
      variables: { cartId },
    });

    if (errors.length) {
      this.setError(errors);
      this.changeIsError(true);
      this.changeIsLoading(false);
      return;
    }

    await this.setCart(Cart.fromAPIResponse(cart, this.actions));
    this.changeIsLoading(false);
  });
}

decorate(CartStore, {
  shoppingCart: observable,
  buyNowCart: observable,
  retrievedCart: observable,
  usedCart: observable,
  cart: computed,
  isLoading: computed,
  isError: observable,
  disableCheckout: observable,
  eventPickerItem: observable,
  isCartExpired: observable,
  containsValidItems: computed,
  cartSize: computed,
  cartSummaryList: computed,
  reservationsValidUntil: computed,
  shoppingCartSeatCount: computed,
  showIncompleteNotice: observable,
  cartId: computed,
  cartIdFromLocalStorage: computed,
  shoppingCartIdFromLocalStorage: computed,
  error: observable,
  noRemainingSeatsError: observable,
  learnerAlreadyRegisteredError: observable,
  submittedPromotionCode: observable,
  cartItemToRebook: observable,
  isApplyPromotionCodeLoading: observable,
});

export default CartStore;
